import { createAsync } from '@solidjs/router';
import { Match, Switch } from 'solid-js';
import { IconCircleCheck } from '@troon/icons';
import { GenericLayout } from '../../layouts/generic';
import { ReservationCtx } from '../../providers/reservation';
import { Error404, ReservationState, gql } from '../../graphql';
import { MessageBanner } from '../../components/message-banner';
import { cachedQuery } from '../../graphql/cached-get';
import type { FacilityHeaderFragment } from '../../graphql';
import type { RouteSectionProps } from '@solidjs/router';

export default function ReservationIdPage(props: RouteSectionProps) {
	const reservation = createAsync(() => getReservation({ id: props.params.reservationId! }), { deferStream: true });
	return (
		<GenericLayout
			hero={(reservation()?.reservation.facility as FacilityHeaderFragment)?.metadata?.hero?.url}
			heroContent={() => (
				<Switch>
					<Match when={reservation()?.reservation.state === ReservationState.Cancelled}>
						<MessageBanner variant="subtle">
							<p>This tee time has been cancelled.</p>
						</MessageBanner>
					</Match>
					<Match when={reservation()?.reservation.state === ReservationState.Completed}>
						<MessageBanner>
							<IconCircleCheck />
							<p>You’re all checked in! Enjoy your round!</p>
						</MessageBanner>
					</Match>
				</Switch>
			)}
		>
			<ReservationCtx.Provider value={reservation}>{props.children}</ReservationCtx.Provider>
		</GenericLayout>
	);
}

const reservationQuery = gql(`
#import '../../components/facility/location.fragment.graphql'
query reservationInformation($id: String!, $rewardType: RedeemableRewardType) {
  reservation(id: $id) {
		id
    teeTimeId
    ownerId
    state
    playerCount
    holeCount
    includesCart
		checkInEnabled
    isCheckInWindow
		isCancelWindow
		isPastStartTime
		shareInviteId
		...ReservationHeader
		calendarEvent {
			google
			yahoo
			outlook
			office365
			ics
		}
    dayTime {
      ...DayTime
    }
		cancelDayTime {
			...DayTime
		}
		cancelFee {
			...Currency
		}
    isToday
		facility {
			name
			...FacilityHeader
			...FacilityLocation
 			metadata {
 			  address {
 			    street
 			    street2
 			    city
 			    state
 			    country
 			    postalCode
 			  }
 			  phone
 			  website
 			}
		}
    course {
      name
			supportsTroonRewards
    }
    users {
			id
      user {
				id
        firstName
        lastName
      }
      state
      isCheckedIn
    }
  }
  userRewards {
    availablePoints
  }
  reservationRedeemableRewards(reservationId: $id) {
    points
    type
    cash {
      displayValue
    }
  }
	reservationPaymentInfo(reservationId: $id, rewardType: $rewardType) {
		receipt {
			...PaymentInfo
		}
	}
}
`);

const getReservation = cachedQuery(reservationQuery, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('does not exist')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});
